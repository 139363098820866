import { color } from "src/styles";
import { create } from "zustand";

export interface InformationalStore {
	backgroundColor: string;
	isFullScreenNav: boolean;
	toggleFullScreenNav: () => void;
	setFullScreenNav: (value: boolean) => void;
	setBackgroundColor: (value: string) => void;
	resetBackgroundColor: () => void;
}

const useInformationalStore = create<InformationalStore>((set, get) => ({
	backgroundColor: color.Background.Dark,
	isFullScreenNav: false,
	toggleFullScreenNav: () => set((state) => ({ isFullScreenNav: !state.isFullScreenNav })),
	setFullScreenNav: (value) => set(() => ({ isFullScreenNav: value })),
	setBackgroundColor: (value) => {
		document.body.style.backgroundColor = value;
		set(() => ({ backgroundColor: value }));
	},
	resetBackgroundColor: () => {
		get().setBackgroundColor(color.Background.Dark);
	},
}));
export default useInformationalStore;
